import React from 'react';
import { useLocation } from '@reach/router';

import Link from "../utils/link";

const CollectionSubmenu = ({ handleLinkClickHandler, activeSubMenu, toggleSubMenu }) => {
  const location = useLocation();
  const isActive = (path) => location.pathname === path ? 'child-active' : '';

  const isSingleActive = (path) => location.pathname === path ? 'child-active' : '';

  return (
    <div className='submenu-container shop'>
      <ul className={`subnav__inner ${activeSubMenu ? 'active' : ''}`}>
        <li className={`parent ${activeSubMenu === 'furniture' ? 'active' : 'inactive'}`}>
          <button onClick={() => toggleSubMenu('furniture')}>
            Furniture
          </button>
          <ul className={`subnav__inner--child child ${activeSubMenu === 'furniture' ? 'active' : 'inactive'}`}>
            <li className={isActive('/shop/seating/')}>
              <Link to={'/shop/seating/'}>Seating & Ottomans</Link></li>
            <li className={isActive('/shop/cabinets-tables/')}>
              <Link to={'/shop/cabinets-tables/'}>Cabinets & Tables</Link></li>

              <li className={`parent ${activeSubMenu === 'lighting' ? 'active' : 'inactive'}`}>
            {/* <li className={isActive('/shop/lighting/')}> */}
              <Link to={'/shop/lighting/'}>Lighting</Link></li>
          </ul>
        </li>


        <li className={`parent ${activeSubMenu === 'rugs' ? 'active' : 'inactive'}`}>
        {/* <li className={isSingleActive('/shop/rugs/')}> */}
          <Link to={'/shop/rugs/'}>Rugs</Link>
        </li>


        {/* <li className={isSingleActive('/shop/art/')}> */}
        <li className={`parent ${activeSubMenu === 'art' ? 'active' : 'inactive'}`}>
          <Link to={'/shop/art/'}>Art</Link>
        </li>


        {/* <li className={`parent ${activeSubMenu === 'homewares' ? 'active' : 'inactive'}`}>
          <button onClick={() => toggleSubMenu('homewares')}>
            Homewares
          </button>
          <ul className={`subnav__inner--child child ${activeSubMenu === 'homewares' ? 'active' : 'inactive'}`}>
            <li className={isActive('/shop/cushions/')}>
              <Link to={'/shop/cushions/'}>Cushions</Link></li>
            <li className={isActive('/shop/rugs/')}>
              <Link to={'/shop/rugs/'}>Rugs</Link></li>
            <li className={isActive('/shop/throws/')}>
              <Link to={'/shop/throws/'}>Throws</Link></li>
          <li className={isActive('/shop/accessories/')}>
              <Link to={'/shop/accessories/'}>Accessories</Link></li>
          </ul>
        </li> */}
          {/* 
        <li className={`parent ${activeSubMenu === 'book' ? 'active' : 'inactive'}`}>
          <Link to={'/shop/book/'}>Book</Link>
        </li> */}


          <li className={`parent ${activeSubMenu === 'fabric' ? 'active' : 'inactive'}`}>
          <button onClick={() => toggleSubMenu('fabric')}>
            Fabric
          </button>

            <ul className={`subnav__inner--child child ${activeSubMenu === 'fabric' ? 'active' : 'inactive'}`}>
              <li className={isActive('/shop/fabric/')}>
                <Link to={'/shop/fabric/'}>All</Link>
              </li>
              <li className={isActive('/shop/pattern/')}>
                <Link to={'/shop/pattern/'}>Pattern</Link></li>
              <li className={isActive('/shop/plain/')}>
                <Link to={'/shop/plain/'}>Plain</Link></li>
            </ul>
          </li>


        <li className={`parent ${activeSubMenu === 'accessories' ? 'active' : 'inactive'}`}>
          <button onClick={() => toggleSubMenu('accessories')}>
            Accessories
          </button>
          <ul className={`subnav__inner--child child ${activeSubMenu === 'accessories' ? 'active' : 'inactive'}`}>
            <li className={isActive('/shop/cushions/')}>
              <Link to={'/shop/cushions/'}>Cushions</Link></li>
            <li className={isActive('/shop/book/')}>
              <Link to={'/shop/book/'}>Book</Link></li>
          </ul>
        </li>


      </ul>
    </div>
  );
};

export default CollectionSubmenu;
