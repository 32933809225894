import React, { useState, useEffect } from 'react';
import { navigate } from "@reach/router";
import StoreContext, { defaultStoreContext } from './store';

const isBrowser = typeof window !== 'undefined';

const Provider = ({ children }) => {
    const [store, updateStore] = useState(defaultStoreContext);

    const getlocalStorage = (value) => {
        try {
            return JSON.parse(localStorage.getItem(value));
        } catch (e) {
            return '';
        }
    };

    useEffect(() => {
        const initializeCheckout = async () => {
            const existingCheckoutID = isBrowser ? localStorage.getItem('shopify_checkout_id') : null;

            const setCheckoutInState = (checkout) => {
                if (isBrowser) {
                    localStorage.setItem('shopify_checkout_id', checkout.id);
                }
                updateStore((state) => ({ ...state, checkout }));
            };

            const createNewCheckout = () => store.client.checkout.create();
            const fetchCheckout = (id) => store.client.checkout.fetch(id);

            if (existingCheckoutID) {
                try {
                    const checkout = await fetchCheckout(existingCheckoutID);

                    if (!checkout.completedAt) {
                        setCheckoutInState(checkout);
                        return;
                    }
                } catch (e) {
                    localStorage.setItem('shopify_checkout_id', null);
                }
            }

            const newCheckout = await createNewCheckout();
            setCheckoutInState(newCheckout);
        };

        initializeCheckout();
    }, [store.client.checkout]);

    return (
        <StoreContext.Provider
            value={{
                store,
                customerAccessToken: getlocalStorage('customerAccessToken'),
                addVariantToCart: async (variantId, quantity) => {
                    updateStore((state) => ({ ...state, adding: true }));
                    const { checkout, client } = store;
                    const checkoutId = checkout.id;
                    const lineItemsToUpdate = [{ variantId, quantity: parseInt(quantity, 10) }];

                    const newCheckout = await client.checkout.addLineItems(checkoutId, lineItemsToUpdate);
                    updateStore((state) => ({ ...state, checkout: newCheckout, adding: false }));
                },
                addVariantToCartAndBuyNow: async (variantId, quantity) => {
                    updateStore((state) => ({ ...state, adding: true }));
                    const { checkout, client } = store;
                    const checkoutId = checkout.id;
                    const lineItemsToUpdate = [{ variantId, quantity: parseInt(quantity, 10) }];

                    const newCheckout = await client.checkout.addLineItems(checkoutId, lineItemsToUpdate);
                    updateStore((state) => ({ ...state, checkout: newCheckout, adding: false }));
                    navigate(newCheckout.webUrl);
                },
                removeLineItem: async (client, checkoutID, lineItemID) => {
                    const newCheckout = await client.checkout.removeLineItems(checkoutID, [lineItemID]);
                    updateStore((state) => ({ ...state, checkout: newCheckout }));
                },
                updateLineItem: async (client, checkoutID, lineItemID, quantity) => {
                    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }];
                    const newCheckout = await client.checkout.updateLineItems(checkoutID, lineItemsToUpdate);
                    updateStore((state) => ({ ...state, checkout: newCheckout }));
                },
                updateFilterType: (type) => {
                    updateStore((state) => ({ ...state, filteredType: type }));
                },
                updateFilterSort: (sort) => {
                    updateStore((state) => ({ ...state, filteredSort: sort }));
                },
                setValue: (value) => {
                    isBrowser && localStorage.setItem('customerAccessToken', JSON.stringify(value));
                    updateStore((state) => ({ ...state, customerAccessToken: value }));
                },
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export default Provider;
