import React, { createContext, useState, useEffect } from 'react';

export const QuoteContext = createContext();

export const QuoteProvider = ({ children }) => {
  const [quoteItems, setQuoteItems] = useState([]);

  useEffect(() => {
    const storedQuote = JSON.parse(localStorage.getItem('quoteItems')) || [];
    setQuoteItems(storedQuote);
  }, []);

  useEffect(() => {
    localStorage.setItem('quoteItems', JSON.stringify(quoteItems));
  }, [quoteItems]);

  const addToQuote = (product, productVariant) => {
    const newQuoteItem = { ...product, productVariant };
    setQuoteItems([...quoteItems, newQuoteItem]);
  };

  const removeFromQuote = (productId, productVariantId) => {
    setQuoteItems(
      quoteItems.filter(
        item => !(item.id === productId && item.productVariant.id === productVariantId)
      )
    );
  };

  const isInQuote = (productId, productVariantId) => {
    return quoteItems.some(
      item => item.id === productId && item.productVariant.id === productVariantId
    );
  };

  return (
    <QuoteContext.Provider value={{ quoteItems, addToQuote, removeFromQuote, isInQuote }}>
      {children}
    </QuoteContext.Provider>
  );
};
