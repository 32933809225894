exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-sister-category-js": () => import("./../../../src/templates/sister-category.js" /* webpackChunkName: "component---src-templates-sister-category-js" */),
  "component---src-templates-sister-journal-js": () => import("./../../../src/templates/sister-journal.js" /* webpackChunkName: "component---src-templates-sister-journal-js" */),
  "component---src-templates-sister-js": () => import("./../../../src/templates/sister.js" /* webpackChunkName: "component---src-templates-sister-js" */),
  "component---src-templates-sister-post-js": () => import("./../../../src/templates/sister-post.js" /* webpackChunkName: "component---src-templates-sister-post-js" */)
}

