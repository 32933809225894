import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import { ReactLenis } from 'lenis/react';

import layoutQuery from '../hooks/use-layout-query';
import '../assets/scss/main.scss';

import Provider from "../context/provider";
import { QuoteProvider } from '../context/QuoteContext';

import Header from '../components/header';
import Footer from '../components/footer';

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery();
  let { site } = pageContext;

  // Set the site context to 'sister' if the page is /cart
  if (typeof window !== 'undefined') {
    if (window.location.pathname === '/cart/') {
      site = 'sister';
    } else if (window.location.pathname === '/sister/journal/') {
      site = 'sister'; 
    } else if (window.location.pathname === '/quote/') {
      site = 'sister'; // or any other logic you need for '/quote/'
    }
  }

  const [isFading, setIsFading] = useState(false);
  const [navigateTo, setNavigateTo] = useState(null);
  const [scrollDirection, setScrollDirection] = useState('none');
  const [scrolled, setScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [quoteQuantity, setQuoteQuantity] = useState(0);

  const lenisRef = useRef();


  const handleLinkClick = (path) => {
    if (typeof window !== 'undefined') {
      if (path === location.pathname) {
        return;
      }
    }

    setNavigateTo(path);
    setIsFading(true);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      // Retrieve the 'quoteItems' from localStorage
      const storedQuoteItems = localStorage.getItem('quoteItems');
  
      console.log(storedQuoteItems);
  
      if (storedQuoteItems) {
        // Parse the JSON string into an array of quote items
        const quoteItems = JSON.parse(storedQuoteItems);
  
        // Calculate the total quantity of quote items
        const totalQuantity = quoteItems.length;
  
        // Set the total quantity in state
        setQuoteQuantity(totalQuantity);
  
        console.log(totalQuantity);
      }
    }, 100);
  
    // Clear the timeout if the component unmounts or changes
    return () => clearTimeout(delay);
  }, []);



  useEffect(() => {
    if (isFading) {
      const timer = setTimeout(() => {
        navigate(navigateTo);
      }, 500); // Duration of the fade-out effect
      return () => clearTimeout(timer);
    }
  }, [isFading, navigateTo]);

  useEffect(() => {
    setIsFading(false);
  }, [location.pathname]);

  let lastScrollTop = 0; // Declare the variable using 'let'

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrolled = scrollTop >= 50;
    let direction = 'none';

    if (scrollTop >= 100) {
      if (scrollTop > lastScrollTop) {
        direction = 'down';
      } else {
        direction = 'up';
      }
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

    setScrollDirection(direction);
    setScrolled(scrolled);
  };

  useEffect(() => {
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerClass = `${
    scrollDirection !== 'none' ? ` scrolled-${scrollDirection}` : ''
  }${scrolled ? ' scrolled' : ''}${isHovered ? ' scrolled-up' : ''}`;


  return (
    <>
      <ReactLenis root>
        <QuoteProvider>
          <Provider>
            <Header
              site={site}
              location={location}
              data={data}
              pageContext={pageContext}
              handleLinkClick={handleLinkClick}
              quoteQuantity={quoteQuantity}
            />
            <div className={`wrapper fade-wrapper ${isFading ? 'fadeout' : 'fadein'}`}>
              <main 
              className={`${headerClass}`}>
                {React.Children.map(children, (child) =>
                  React.cloneElement(child, { handleLinkClick, setQuoteQuantity, quoteQuantity })
                )}
              </main>
              <Footer 
                site={site}
                data={data}
                handleLinkClick={handleLinkClick}
              />
            </div>
          </Provider>
        </QuoteProvider>
      </ReactLenis>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

export default Layout;



// import React, { useState, useEffect, useRef } from 'react';
// import { navigate } from 'gatsby';
// import PropTypes from 'prop-types';
// import { ReactLenis } from 'lenis/react';

// import layoutQuery from '../hooks/use-layout-query';
// import '../assets/scss/main.scss';

// import Provider from "../context/provider";
// import { QuoteProvider } from '../context/QuoteContext';

// import Header from '../components/header';
// import Footer from '../components/footer';

// const Layout = ({ children, location, pageContext }) => {
//   const data = layoutQuery();
//   let { site } = pageContext;

//   // Set the site context to 'sister' if the page is /cart
//   if (typeof window !== 'undefined') {
//     if (window.location.pathname === '/cart/') {
//       site = 'sister';
//     } else if (window.location.pathname === '/sister/journal/') {
//       site = 'sister'; 
//     } else if (window.location.pathname === '/quote/') {
//       site = 'sister'; // or any other logic you need for '/quote/'
//     }
//   }
//   const [scrollDirection, setScrollDirection] = useState('none');
//   const [scrolled, setScrolled] = useState(false);
//   const [isFading, setIsFading] = useState(false);
//   const [isHovered, setIsHovered] = useState(false);
//   const [navigateTo, setNavigateTo] = useState(null);
//   const lenisRef = useRef();


//   let lastScrollTop = 0; // Declare the variable using 'let'

//   const handleScroll = () => {
//     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
//     const scrolled = scrollTop >= 50;
//     let direction = 'none';

//     if (scrollTop >= 100) {
//       if (scrollTop > lastScrollTop) {
//         direction = 'down';
//       } else {
//         direction = 'up';
//       }
//     }

//     lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

//     setScrollDirection(direction);
//     setScrolled(scrolled);
//   };

//   const handleHeaderHover = () => {
//     setIsHovered((prevIsHovered) => !prevIsHovered);
//     setScrollDirection('none');
//   };

//   useEffect(() => {
//     let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);


//   const handleLinkClick = (path) => {
//     if (typeof window !== 'undefined') {
//       if (path === location.pathname) {
//         return;
//       }
//     }

//     setNavigateTo(path);
//     setIsFading(true);
//   };

//   useEffect(() => {
//     if (isFading) {
//       const timer = setTimeout(() => {
//         navigate(navigateTo);
//       }, 1000); // Duration of the fade-out effect
//       return () => clearTimeout(timer);
//     }
//   }, [isFading, navigateTo]);

//   useEffect(() => {
//     setIsFading(false);
//   }, [location.pathname]);

//   const scrollClass = `${
//     scrollDirection !== 'none' ? ` scrolled-${scrollDirection}` : ''
//   }${scrolled ? ' scrolled' : ''}${isHovered ? ' scrolled-up' : ''}`;


//   return (
//     <>
//       <ReactLenis root>
//         <QuoteProvider>
//           <Provider>
//             <Header
//               site={site}
//               location={location}
//               data={data}
//               pageContext={pageContext}
//               handleLinkClick={handleLinkClick}
//             />
//             <div className={`wrapper fade-wrapper ${isFading ? 'fadeout' : 'fadein'}`}>
//               <main className={`${scrollClass}`}>
//                 {React.Children.map(children, (child) =>
//                   React.cloneElement(child, { handleLinkClick })
//                 )}
//               </main>
//               <Footer 
//                 site={site}
//                 data={data}
//                 handleLinkClick={handleLinkClick}
//               />
//             </div>
//           </Provider>
//         </QuoteProvider>
//       </ReactLenis>
//     </>
//   );
// };

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
//   location: PropTypes.object,
//   pageContext: PropTypes.object,
// };

// export default Layout;
