import React, { useState, useEffect } from 'react';
import Link from '../utils/link';
import { Logo, LogoSister } from '../components/icons';
import Fade from 'react-reveal/Fade';
import { useInView } from 'react-intersection-observer';
import layoutQuery from "../hooks/use-layout-query";
import NewsletterPopup from '../components/newsletter-form';
import NewsletterPopupSister from '../components/newsletter-form-sister';

const Footer = ({ site, handleLinkClick }) => {
  const data = layoutQuery();
  const [activeLink, setActiveLink] = useState(null);
  const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);
  const [fadePopup, setFadePopup] = useState(false); // New state for fade effect
  const siteName = site || 'studio-ashby';

  const handleLinkClickHandler = (link) => {
    setActiveLink(link);
    handleLinkClick(link);
  };

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const handleSubscribeClick = (e) => {
    e.preventDefault();
    setFadePopup(true); // Start fade-in effect
    setShowNewsletterPopup(true);
  };

  const handleClosePopup = (e) => {
    e.preventDefault();
    setFadePopup(false); // Start fade-out effect
    // Delay the actual hiding of the popup to allow the fade-out effect to finish
    setTimeout(() => {
      setShowNewsletterPopup(false);
    }, 300); // Adjust timing to match your fade-out duration
  };

  // UseEffect to show popup after 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setFadePopup(true); // Start fade-in effect
      setShowNewsletterPopup(true);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer); // Clear timer on unmount
  }, []);

  return (
    <>
      <NewsletterPopup 
        showModal={showNewsletterPopup}
        show={showNewsletterPopup} 
        hidePopup={handleClosePopup}
        fadePopup={fadePopup} // Pass fade state for CSS class
      />

      {siteName !== 'sister' ? (
        <footer ref={ref} className={`site--${siteName} footer`}>
          {inView && (
            <Fade distance='30px' bottom>
              <div className='footer__inner'>
                <div className='footer__col'>
                  <div className="logo">
                    <Link to='/' title='Studio Ashby' className='footer__logo'
                      onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/');
                      }}
                    >
                      <Logo color='#AE9891'/>
                    </Link>
                  </div>
                </div>
                <div className='footer__col'>
                  <nav>
                    <ul>
                      <li>
                        <Link 
                          to='/contact'
                          onClick={(e) => {
                            e.preventDefault();
                            handleLinkClickHandler('/contact/');
                          }}
                        >Contact</Link>
                      </li>
                      <li>
                        <Link to='https://www.instagram.com/studioashby'>Instagram</Link>
                      </li>
                      <li>
                        <Link 
                          to='/careers'
                          onClick={(e) => {
                            e.preventDefault();
                            handleLinkClickHandler('/careers/');
                          }}
                        >Careers</Link>
                      </li>
                      <li>
                        <Link onClick={handleSubscribeClick}>Subscribe</Link>
                      </li>
                      <li>
                        <Link
                          to='/privacy-policy'
                          onClick={(e) => {
                            e.preventDefault();
                            handleLinkClickHandler('/privacy-policy/');
                          }}
                        >Privacy Policy</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className='footer__col'>
                  <p className='copyright'>
                    &copy; {new Date().getFullYear()} Studio Ashby<br/>
                    Site by <Link to='https://studiosmall.com'>StudioSmall</Link>
                  </p>
                </div>
              </div>
            </Fade>
          )}
        </footer>
      ) : (
        <footer ref={ref} className={`site--${siteName} footer`}>
          {inView && (
            <Fade distance='30px' bottom>
              <div className='footer__inner'>
                <div className='footer__col'>
                  <p className='copyright'>
                    &copy; {new Date().getFullYear()} Studio Ashby<br/>
                    Site by <Link to='https://studiosmall.com'>StudioSmall</Link>
                  </p>
                </div>
                <div className='footer__col'>
                  <nav>
                    <ul>
                      <li>
                        <Link 
                          to='/sister/contact/'
                          onClick={(e) => {
                            e.preventDefault();
                            handleLinkClickHandler('/sister/contact/');
                          }}
                        >Contact</Link>
                      </li>
                      <li>
                        <Link to='https://www.instagram.com/sisterbystudioashby'>Instagram</Link>
                      </li>
                      <li>
                        <Link 
                          to='/careers'
                          onClick={(e) => {
                            e.preventDefault();
                            handleLinkClickHandler('/careers/');
                          }}
                        >Careers</Link>
                      </li>
                      <li>
                        <Link onClick={handleSubscribeClick}>Subscribe</Link>
                      </li>
                      <li>
                        <Link 
                          to='/sister/terms-and-conditions/'
                          onClick={(e) => {
                            e.preventDefault();
                            handleLinkClickHandler('/sister/terms-and-conditions/');
                          }}
                        >Terms & Conditions</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className='footer__col'>
                  <div className="logo__sister">
                    <Link
                      title='Sister by Studio Ashby' className='footer__logo'
                      to='/sister/' 
                      onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/sister/');
                      }}
                    >
                      <LogoSister color='#ffffff'/>
                    </Link>
                  </div>
                </div>
              </div>
            </Fade>
          )}
        </footer>
      )}
    </>
  );
}

export default Footer;
