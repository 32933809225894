import React, { useContext, useState, useEffect } from 'react';
import { Logo, LogoMark, LogoSister, LogoSisterMark } from '../components/icons';
import { useLocation } from '@reach/router';
import StoreContext from '../context/store';
import Fade from 'react-reveal/Fade';
import Link from "../utils/link"
import CollectionSubmenu from '../components/CollectionSubmenu';

// Function to count total quantity
const countQuantity = lineItems => {
  let quantity = 0;
  lineItems.forEach(item => {
    quantity += item.quantity;
  });
  return quantity;
};

const Header = ({ site, handleLinkClick, quoteQuantity }) => {
  const location = useLocation();
  const [scrollDirection, setScrollDirection] = useState('none');
  const [scrolled, setScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [offCanvas, setOffCanvas] = useState(false);
  const [activeParentMenu, setActiveParentMenu] = useState(null); // Track which parent menu is active
  const [activeSubMenu, setActiveSubMenu] = useState(null); // Track which sub-menu is active

  const context = useContext(StoreContext);
  const checkout = context.store ? context.store.checkout : null; // Safely access context.store.checkout
  const [quantity, setQuantity] = useState(countQuantity(checkout ? checkout.lineItems : []));

  // const [quoteQuantity, setQuoteQuantity] = useState(0);

  const siteName = site || 'studio-ashby';

  let lastScrollTop = 0; // Declare the variable using 'let'

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrolled = scrollTop >= 50;
    let direction = 'none';

    if (scrollTop >= 100) {
      if (scrollTop > lastScrollTop) {
        direction = 'down';
      } else {
        direction = 'up';
      }
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;

    setScrollDirection(direction);
    setScrolled(scrolled);
  };

  const handleHeaderHover = () => {
    setIsHovered((prevIsHovered) => !prevIsHovered);
    setScrollDirection('none');
  };

  // Toggle off-canvas menu
  const toggleOffCanvas = () => setOffCanvas(prevState => !prevState);

  const hideOffCanvas = () => setOffCanvas(false);

  // Handle link click
  const handleLinkClickHandler = link => {
    handleLinkClick(link);
    hideOffCanvas();
  };

  useEffect(() => {
    let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const headerClass = `header${
    scrollDirection !== 'none' ? ` scrolled-${scrollDirection}` : ''
  }${scrolled ? ' scrolled' : ''}${isHovered ? ' scrolled-up' : ''}${offCanvas ? ' offcanvas-active' : ''}`;

  // Toggle parent menu visibility
  const toggleParentMenu = parentMenu => {
    // handleLinkClick('/sister/collection');
    if (activeParentMenu === parentMenu) {
      setActiveParentMenu(null);
      setActiveSubMenu(null); // Reset sub-menu when switching parent menu
    } else {
      setActiveParentMenu(parentMenu);
      setActiveSubMenu(null); // Reset sub-menu when switching parent menu
    }
  };

  // Toggle sub-menu visibility
  // const toggleSubMenu = subMenu => {
  //   console.log('Toggling sub-menu:', subMenu);
  //   if (activeParentMenu === 'collection') {
  //     if (activeSubMenu === subMenu) {
  //       setActiveSubMenu(null);
  //     } else {
  //       setActiveSubMenu(subMenu);
  //     }
  //   }
  // };

  const toggleSubMenu = (subMenu) => {
    setActiveSubMenu((prev) => prev === subMenu ? null : subMenu);
  };

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []));
  }, [checkout]);

  const isActive = (path) => location.pathname === path ? 'active' : '';
  const isActiveJournal = (path) => location.pathname === path ? 'active-journal' : '';

  const isJournalPage = location.pathname.includes('journal');


  useEffect(() => {
    // Map sub-category paths to their parent sub-menu
    const subMenuMapping = {
      '/shop/seating/': 'furniture',
      '/shop/tables/': 'furniture',
      '/shop/lighting/': 'furniture',
      '/shop/cabinets-tables/': 'furniture',
      // '/shop/cushions/': 'homewares',
      // '/shop/rugs/': 'homewares',
      '/shop/book/': 'accessories',
      '/shop/cushions/': 'accessories',
      '/shop/pattern/': 'fabric',
      '/shop/plain/': 'fabric',
      '/shop/fabric/': 'fabric'
    };

    // Find the active sub-menu based on the current path
    const activeSubMenu = Object.keys(subMenuMapping).find(path => location.pathname.startsWith(path));
    setActiveSubMenu(subMenuMapping[activeSubMenu] || null);
  }, [location.pathname]);

  const keywordsFabric = ['fabric', 'pattern', 'plain'];
  const keywordsCollection = ['collection',
                              'furniture',
                              'seating',
                              'tables',
                              'lighting',
                              'cabinets-tables',
                              'homewares',
                              'cushions',
                              'rugs',
                              'throws',
                              'accessories',
                              'book',
                              'art',
                              'pattern',
                              'plain',
                              'fabric'
                            ];
  const shouldDisplaySubmenuFabric = keywordsFabric.some(keyword => location.pathname.includes(keyword));

  const shouldDisplaySubmenuCollection = keywordsCollection.some(keyword => {
    const regex = new RegExp(`\\b${keyword}\\b`, 'i');
    return regex.test(location.pathname);
  });

  return (
    <>
      {siteName !== 'sister' ? (
        <>
        <header className={`site--${siteName} ${headerClass}`} onMouseEnter={handleHeaderHover} onMouseLeave={handleHeaderHover}>
          <div className="header__inner">
            <div className="header__row">
              <div className="header__col">
                <div className="logo">
                  <Link
                    to='/'
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClickHandler('/');
                    }}
                  >
                    <Logo color="#F9F3F0" />
                  </Link>
                </div>
              </div>

              <div className="header__col navigation">
                <nav>
                  <ul>
                    <li className={isActive('/projects/')}>
                      <Link
                        to={'/projects/'}
                       onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/projects/');
                      }}
                      >Projects</Link>
                    </li>
                    <li className={isActive('/about/')}>
                      <Link
                      to={'/about/'}  
                        onClick={(e) => {
                          e.preventDefault();
                          handleLinkClickHandler('/about/');
                        }}
                        >About</Link>
                    </li>
                    <li className={isActive('/contact/')}>
                      <Link 
                      to={'/contact/'}  
                      onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/contact/');
                      }}
                      >Contact</Link>
                    </li>
                    <li className={isActive('/sister/')}>
                      <Link
                      to={'/sister/'}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/sister/');
                      }}
                      >Store</Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="header__col">
                <div className="logo-mark">
                  <Link 
                  to={'/sister/'}
                  onClick={(e) => {
                    e.preventDefault();
                    handleLinkClickHandler('/sister/');
                  }}
                  >
                    <LogoSisterMark color="#F9F3F0" />
                  </Link>
                </div>

                <button onClick={toggleOffCanvas} className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}>
                  <span className="hamburger">
                    <span className='lines'></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </header>

        <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
            <div className='off-canvas__inner'>
                <nav className='off-canvas__nav'>
                  <ul>
                    <li className={isActive('/projects/')}>
                      <Link
                      to={'/projects/'}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/projects/');
                      }}
                      >Projects</Link>
                    </li>
                    <li className={isActive('/about/')}>
                      <Link
                      to={'/about/'}  
                      onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/about/');
                      }}
                      >About</Link>
                    </li>
                    <li className={isActive('/contact/')}>
                      <Link
                      to={'/contact/'}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/contact/');
                      }}
                      
                      >Contact</Link></li>
                      <li className={isActive('/sister/')}>
                      <Link
                      to={'/sister/'}
                       onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/sister/');
                      }}
                      
                      >Store</Link>
                    </li>
                  </ul>
                </nav>
            </div>
            <div className='off-canvas__footer sister'>
              <div className='off-canvas__footer--inner right'>
                <div className="logo-sister">
                  <button onClick={() => handleLinkClickHandler('/sister/')}>
                    <LogoSister color="#F9F3F0" />
                  </button>
                </div>

                <div className='arrow'>
                  <svg width="10" height="18" viewBox="0 0 10 18" fill="none">
                    <path d="M9 17L1 9L9 1" stroke="#F9F3F0"/>
                  </svg>
                </div>

              </div>
            </div>

            {isJournalPage && (
              <div className="submenu-container">
                <ul>
                  <li className={isActiveJournal('/sister/journal/news/')}>
                    <Link to={'/sister/journal/news'}>News</Link>
                  </li>
                  <li className={isActiveJournal('/sister/journal/events/')}>
                    <Link to={'/sister/journal/events'}>Events</Link>
                  </li>
                  <li className={isActiveJournal('/sister/journal/artist-in-residence/')}>
                    <Link to={'/sister/journal/artist-in-residence'}>Artist in Residence</Link>
                  </li>
                </ul>
              </div>
            )}
            
            {/* {shouldDisplaySubmenuFabric && (
              <div className='submenu-container'>
                <ul>
                  <li className={`nav-item ${isActive('/shop/pattern/')}`}>
                    <Link to={'/shop/pattern/'}>Pattern</Link>
                  </li>
                  <li className={`nav-item ${isActive('/shop/plain/')}`}>
                    <Link to={'/shop/plain/'}>Plain</Link>
                  </li>
                </ul>
              </div>
            )} */}

            {shouldDisplaySubmenuCollection && (
              <CollectionSubmenu 
                handleLinkClickHandler={handleLinkClickHandler}
                activeSubMenu={activeSubMenu}
                toggleSubMenu={toggleSubMenu}
              />
            )}
          </div>
        </>
      ) : (
        <>
        <header className={`site--${siteName} ${headerClass}`} onMouseEnter={handleHeaderHover} onMouseLeave={handleHeaderHover}>
          <div className="header__inner">
            <div className="header__row">
              <div className="header__col">
                <div className="logo">
                  <button onClick={() => handleLinkClickHandler('/')}>
                    <LogoMark color="#F9F3F0" />
                  </button>
                </div>
              </div>

              <div className="header__col navigation">
                <nav>
                  <ul>
                    <li className={isActive('/')}>
                      <button onClick={() => handleLinkClickHandler('/')}>Studio</button></li>
                   
                    {/* <li className={`parent ${activeParentMenu === 'collection' ? 'active' : ''}`}>
                      <button onClick={() => toggleParentMenu('collection')}>Collection</button>
                      <div className={`subnav collection ${activeParentMenu === 'collection' ? 'active' : ''}`}>
                        <ul className={`subnav__inner ${['furniture', 'art', 'homewares', 'book'].includes(activeSubMenu) ? 'active' : ''}`}>
                          <li className={`parent ${activeSubMenu === 'furniture' ? 'active' : 'inactive'}`}>
                            <button onClick={() => {
                              toggleSubMenu('furniture');
                              setActiveParentMenu('collection');
                            }}>Furniture</button>
                            <ul className={`subnav__inner--child child ${['furniture', 'art', 'homewares', 'book'].includes(activeSubMenu) ? 'active' : 'inactive'}`}>
                              <li><button onClick={() => handleLinkClickHandler('/shop/seating/')}>Seating</button></li>
                              <li><button onClick={() => handleLinkClickHandler('/shop/tables/')}>Tables</button></li>
                            </ul>
                          </li>
                          <li className={`parent ${activeSubMenu === 'art' ? 'active' : 'inactive'}`}>
                            <button onClick={() => {
                              handleLinkClickHandler('/shop/art/')
                            }}>Art</button>
                          </li>
                          <li className={`parent ${activeSubMenu === 'homewares' ? 'active' : 'inactive'}`}>
                            <button onClick={() => {
                              toggleSubMenu('homewares');
                              setActiveParentMenu('collection');
                            }}>Homewares</button>
                            <ul className={`subnav__inner--child child ${activeSubMenu === 'homewares' ? 'active' : 'inactive'}`}>
                              <li><button onClick={() => handleLinkClickHandler('/shop/cushions/')}>Cushions</button></li>
                              <li><button onClick={() => handleLinkClickHandler('/shop/rugs/')}>Rugs</button></li>
                              <li><button onClick={() => handleLinkClickHandler('/shop/throws/')}>Throws</button></li>
                              <li><button onClick={() => handleLinkClickHandler('/shop/accessories/')}>Accessories</button></li>
                            </ul>
                          </li>

                          
                          
                          <li className={`parent ${activeSubMenu === 'book' ? 'active' : 'inactive'}`}>
                            <button onClick={() => {
                              handleLinkClickHandler('/shop/book/')
                            }}>Book</button>
                          </li>

                        </ul>
                      </div>
                    </li> */}
                    <li className={isActive('/shop/collection/')}>
                      <Link
                      to={'/shop/collection/'}
                       onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/shop/collection/');
                      }}
                      >Collection</Link>
                    </li>
                
                    <li className={isActive('/sister/about/')}>

                      <Link
                      to={'/sister/about/'}
                       onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/sister/about/');
                      }}
                      >About</Link>

                    </li>
                    <li className={isActive('/sister/journal/')}>
                      <Link
                      to={'/sister/journal/'}
                       onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/sister/journal/');
                      }}
                      >Journal</Link>

                    </li>
                    <li className={isActive('/sister/contact/')}>
                      
                    <Link
                      to={'/sister/contact/'}
                       onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/sister/contact/');
                      }}
                      >Contact</Link>
                    </li>

                    <li className='navigation-cart'>
                        <button className='cart-btn' aria-label="cart" onClick={() => handleLinkClickHandler('/cart/')}>
                          {quantity > 0 ? (
                            <div className="shopping-bag-quantity">BASKET
                             {/* (<span>{quantity}</span>) */}
                            </div>
                          ) : (
                            <div className="shopping-bag-quantity">BASKET
                            {/* (<span>0</span>) */}
                            </div>
                          )}
                        </button>
                      </li>
                  </ul>
                </nav>
              </div>

              <div className="header__col">
                <div className="logo-mark">
                  <button onClick={() => handleLinkClickHandler('/sister/')}>
                    <LogoSister color="#F9F3F0" />
                  </button>
                </div>

                <div className='header__cart mobile'>
                <Fade delay='850'>
                  <button className='cart-btn' aria-label="cart" onClick={() => handleLinkClickHandler('/cart')}>
                    {quantity > 0 ? (
                      <div className="shopping-bag-quantity">(<span>{quantity}</span>)</div>
                    ) : (
                      <div className="shopping-bag-quantity">(<span>0</span>) </div>
                    )}
                  </button>
                </Fade>
              </div>

                <button onClick={toggleOffCanvas} className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}>
                  <span className="hamburger">
                    <span className='lines'></span>
                  </span>
                </button>
              </div>
            </div>

            {/* <div className='header__row'>
              <div className='header__cart desktop'>
                <Fade delay='850'>
                  <button className='cart-btn' aria-label="cart" onClick={() => handleLinkClickHandler('/cart/')}>
                    {quantity > 0 ? (
                      <div className="shopping-bag-quantity">BASKET (<span>{quantity}</span>)</div>
                    ) : (
                      <div className="shopping-bag-quantity">BASKET (<span>0</span>) </div>
                    )}
                  </button>

                  <button className='quote-btn' aria-label="quote" onClick={() => handleLinkClickHandler('/quote/')}>
                  {quoteQuantity > 0 ? (
                     <div className="shopping-quote-quantity">Quote (<span>{quoteQuantity}</span>)</div>
                  ) : (
                    <div className="shopping-quote-quantity">Quote (<span>0</span>)</div>
                  )}
                  </button>
                </Fade>
              </div>
            </div> */}
          </div>
          {isJournalPage && (
            <div className="submenu-container">
              <ul>
                <li className={isActiveJournal('/sister/journal/news/')}>
                  <Link to={'/sister/journal/news'}>News</Link>
                </li>
                <li className={isActiveJournal('/sister/journal/events/')}>
                  <Link to={'/sister/journal/events'}>Events</Link>
                </li>
                <li className={isActiveJournal('/sister/journal/artist-in-residence/')}>
                  <Link to={'/sister/journal/artist-in-residence'}>Artist in Residence</Link>
                </li>
              </ul>
            </div>
          )}

          {/* {shouldDisplaySubmenuFabric && (
            <div className='submenu-container'>
              <ul>
                <li className={`nav-item ${isActive('/shop/pattern/')}`}>
                  <Link to={'/shop/pattern/'}>Pattern</Link>
                </li>
                <li className={`nav-item ${isActive('/shop/plain/')}`}>
                  <Link to={'/shop/plain/'}>Plain</Link>
                </li>
              </ul>
            </div>
          )} */}

          {shouldDisplaySubmenuCollection && (
            <CollectionSubmenu 
              handleLinkClickHandler={handleLinkClickHandler}
              activeSubMenu={activeSubMenu}
              toggleSubMenu={toggleSubMenu}
            />
          )}
        </header>

          <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
            <div className='off-canvas__inner'>
              {/* <button className='off-canvas__menu-close' onClick={hideOffCanvas}>
                <Close color={'#1D1D1B'} />
              </button> */}

                <nav className='off-canvas__nav'>
                  <ul>
                    <li className={isActive('/shop/collection/')}>
                      <Link
                        to={'/shop/collection/'}
                        onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/shop/collection/');
                      }}>
                      Collection
                      </Link>
                      
                      </li>
                    {/* <li className={isActive('/shop/fabric/')}>
                      <Link
                        to={'/shop/fabric'}
                        onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/shop/fabric');
                      }}>
                      Fabric
                      </Link>
                      </li> */}
                    <li className={isActive('/shop/about/')}>
                      <Link
                        to={'/sister/about'}
                        onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/sister/about');
                      }}>
                      About
                      </Link>
                    </li>
                    <li className={isActive('/sister/journal/')}>
                      <Link
                        to={'/sister/journal/'}
                        onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/sister/journal/');
                      }}>
                      Journal
                      </Link>
                    </li>
                    <li className={isActive('/sister/contact/')}>
                      <Link
                        to={'/sister/contact/'}
                        onClick={(e) => {
                        e.preventDefault();
                        handleLinkClickHandler('/sister/contact/');
                      }}>
                      Contact
                      </Link>
                      </li>
                  </ul>
                </nav>
            </div>
            <div className='off-canvas__footer'>
              <div className='off-canvas__footer--inner'>
                <div className='arrow'>
                  <svg width="10" height="18" viewBox="0 0 10 18" fill="none">
                    <path d="M9 17L1 9L9 1" stroke="#F9F3F0"/>
                  </svg>
                </div>

                <div className="logo">
                  <button onClick={() => handleLinkClickHandler('/')}>
                    <Logo color="#F9F3F0" />
                  </button>
                </div>

              </div>
            </div>
          </div>

        </>
      )}
    </>
  );
};

export default Header;
