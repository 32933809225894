import React, { useReducer, createContext } from 'react';
import Client from 'shopify-buy';

const client = Client.buildClient({
  domain: `${process.env.GATSBY_SHOPIFY_STORE_URL}`,
  storefrontAccessToken: `${process.env.GATSBY_STOREFRONT_ACCESS_TOKEN}`,
});

export const defaultStoreContext = {
  client,
  adding: false,
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  filteredType: 'all',
  filteredSort: 'featured',
  customerAccessToken: null,
  setValue: () => { },
  addVariantToCart: () => { },
  addVariantToCartAndBuyNow: () => { },
  removeLineItem: () => { },
  updateLineItem: () => { },
};

const StoreContext = createContext(defaultStoreContext);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CHECKOUT':
      return { ...state, checkout: action.payload };
    default:
      return state;
  }
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultStoreContext);

  const updateLineItem = (client, checkoutId, lineItemId, quantity) => {
    client.checkout.updateLineItems(checkoutId, [{ id: lineItemId, quantity }])
      .then((checkout) => {
        dispatch({ type: 'SET_CHECKOUT', payload: checkout });
      });
  };

  return (
    <StoreContext.Provider value={{ ...state, updateLineItem }}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreContext;
